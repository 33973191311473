import './dashboardMenuCommp.scss';
import React, { useCallback, useEffect, useState } from 'react';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator, { listItemDecoratorClasses } from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import { Divider, Grid, IconButton, Menu, MenuItem, Popover, Tooltip } from '@mui/material';
import { NMS_TYPES, NMS_STATUS } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { authenticate, clearAuthToken, logout } from 'app/shared/reducers/authentication';
import { useNavigate } from 'react-router-dom';
import { isImportTypeAssertionContainer } from 'typescript';
import { getAllNotStartedSessionsByPharmacy, getAllSessionsByPharmacy, getPharmacyById } from 'app/entities/session/session.reducer';
import InfoIcon from '@mui/icons-material/Info';
import GroupIcon from '@mui/icons-material/Group';
import BoyIcon from '@mui/icons-material/Boy';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import zIndex from '@mui/material/styles/zIndex';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { isNull } from 'lodash';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { keyframes } from '@mui/system';
import { useDashboardContext } from 'app/shared/layout/header/LeftBarContext';

export const DashboardMenuCommp = props => {
  const { sessionCounts, handleSessionStatusValue, isAnimationActive } = props;
  const page = useAppSelector(state => state.session.pageNo);
  const rowsPerPage = useAppSelector(state => state.session.rows);
  const [index, setIndex] = React.useState(0);
  const [showOptions, setShowOptions] = React.useState(false);
  const [values, setValues] = useState({ service_id: 1, session_type: null, session_status: null });
  const [interventionCount, setInterventionCount] = useState(0);
  const [followUpCount, setFollowUpCount] = useState(0);
  const [overdueCount, setOverdueCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [notConsentCount, setNoConsentCount] = useState(0);
  const [notReachableCount, setNotReachableCount] = useState(0);
  const [isSearch, setSearch] = useState(false);
  const [selectedColor, setSelectedColor] = useState('one');
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(state => state.authentication.account);
  const pharmacyName = localStorage.getItem('pharmacy_name') === null ? '' : localStorage.getItem('pharmacy_name');
  const managerName = localStorage.getItem('manager_name') === null ? '' : localStorage.getItem('manager_name');
  const pharmacyId = localStorage.getItem('pharmacy_id') === null ? '' : localStorage.getItem('pharmacy_id');
  const pharmacyDetail = useAppSelector(state => state.session.pharmacyDetail);
  const role = localStorage.getItem('role') === null ? '' : localStorage.getItem('role');
  let pharmacistType = JSON.parse(localStorage.getItem('pharmacist_type'));
  const { showMenu } = useDashboardContext();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowOptions(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleClose();
    navigate('/login');
  };

  const getPharmacyBy = useCallback(
    async (id: number) => {
      await dispatch(getPharmacyById(id));
    },
    [Number(pharmacyId)]
  );

  useEffect(() => {
    setInterventionCount(sessionCounts ? sessionCounts['interventionCount'] : 0);
    setFollowUpCount(sessionCounts ? sessionCounts['followUpCount'] : 0);
    setOverdueCount(sessionCounts ? sessionCounts['overdueCount'] : 0);
    setCompletedCount(sessionCounts ? sessionCounts['completedCount'] : 0);
    setNoConsentCount(sessionCounts ? sessionCounts['noConsentCount'] : 0);
    setNotReachableCount(sessionCounts ? sessionCounts['noReachableCount'] : 0);
  }, [sessionCounts]);

  useEffect(() => {
    getPharmacyBy(Number(pharmacyId));
  }, [Number(pharmacyId)]);

  const handleSessionListing = e => {
    const appUserId = localStorage.getItem('id');
    const sessionTypeValues = e.currentTarget.getAttribute('data-session-type');
    const dataIndex = e.currentTarget.getAttribute('data-index');
    const sessionStatusValues = e.currentTarget.getAttribute('data-session-status');
    setIndex(dataIndex);
    handleSessionStatusValue(sessionStatusValues);
    const data = {
      pageNo: 0,
      rows: rowsPerPage,
      q: '',
      service_id: 1,
      session_type: sessionTypeValues,
      session_status: sessionStatusValues,
      sort: {
        address: false,
        fullName: false,
        nextSession: false,
      },
    };
    setTimeout(() => {
      dispatch(getAllSessionsByPharmacy(data));
    }, 50);
    dispatch(getAllNotStartedSessionsByPharmacy());
  };
  const flashAnimation = keyframes`
  0% { background-position: -100% 0; }
  100% { background-position: 100% 0; }
`;
  return (
    <Grid container style={{ height: '100%' }}>
      <Grid
        lg={showMenu ? 0.6 : 2.1}
        xs={12}
        sm={showMenu ? 1 : 3}
        container
        style={{
          background: 'transparent linear-gradient(164deg, #164AAA 0%, rgb(82, 200, 0) 100%) 0% 0% no-repeat padding-box',
          justifyContent: 'center', // Center content
          transition: 'ease 0.4s',
          position: 'fixed',
          height: 'inherit',
          top: 0,
          opacity: 0.95,
          maxWidth: showMenu ? '4%' : '15%',
        }}
      >
        {/* <Divider style={{ backgroundColor: 'lightgray', height: 3, alignSelf: 'flex-end', marginLeft: 0 }} /> */}
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
          <img
            src={'../../../content/logos/login-bg.png'}
            alt="background"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              opacity: 0.2,
            }}
          />
        </div>

        {showMenu === false ? (
          <Grid
            lg={10}
            xs={10}
            sm={10}
            style={{
              textAlign: 'center', // Center-align content
              zIndex: 99999,
            }}
          >
            <Grid
              item
              style={{
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center', // Vertically align items
                justifyContent: 'center', // Adjust horizontal alignment
              }}
            >
              <img
                src={'../../../../content/logos/final-logo.png'}
                alt="Logo"
                style={{
                  width: 'auto',
                  height: '45px',
                }}
              />
              <span
                style={{
                  color: 'white',
                  fontWeight: 600,
                  marginLeft: '10px', // Add space between image and text
                  textAlign: 'center',
                }}
              >
                Pharmacy Solutions
              </span>
            </Grid>
            <hr style={{ marginTop: 0, color: 'white' }} />
            <List
              aria-label="Sidebar"
              sx={{
                marginTop: '30px',
                '--ListItem-paddingLeft': '0px',
                '--ListItemDecorator-size': '20px',
                '--ListItem-minHeight': '10px',
                '--List-nestedInsetStart': '10px',
                height: '30%',
                width: '110%',
              }}
            >
              <ListItem className={'custom_list_item_css'}>
                <ListItemButton
                  onClick={() => (showOptions === false ? setShowOptions(true) : setShowOptions(false))}
                  className={'custom_list_button_css'}
                  style={{
                    backgroundColor: selectedColor === ('one' || 'two') ? '#99D7FF' : 'transparent',
                  }}
                >
                  <img
                    src={
                      selectedColor === ('one' || 'two')
                        ? '../../../../content/assets/in-progress.png'
                        : '../../../../content/assets/in-progress-white.png'
                    } // Use the image URL from the array
                    alt="logo"
                    className={'menu_bar_img'}
                  />
                  <ListItemContent
                    className={'custom_list_item_content_css'}
                    style={{ color: selectedColor === ('one' || 'two') ? '#004CB2' : 'white' }}
                  >
                    In Progress
                    {showOptions ? (
                      <ArrowDropUpIcon style={{ color: selectedColor === ('one' || 'two') ? '#004cb2' : 'white', float: 'right' }} />
                    ) : (
                      <ArrowDropDownIcon style={{ color: selectedColor === ('one' || 'two') ? '#004cb2' : 'white', float: 'right' }} />
                    )}
                  </ListItemContent>
                </ListItemButton>
              </ListItem>

              <Popover
                open={showOptions}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 100, left: 300 }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <List>
                  {pharmacistType === 'intervention' || pharmacistType === 'both' ? (
                    <ListItem
                      onClick={handleSessionListing}
                      data-session-status={NMS_STATUS.IN_PROGRESS}
                      data-session-type={NMS_TYPES.INTERVENTION}
                    >
                      <ListItemButton
                        data-session-type={NMS_TYPES.INTERVENTION}
                        data-session-status={NMS_STATUS.IN_PROGRESS}
                        selected={index === 1}
                        style={{ borderRadius: '10px' }}
                        onClick={() => {
                          setSelectedColor('one');
                        }}
                      >
                        <img
                          src={'../../../../content/assets/intervention-logo.png'}
                          alt="Intervention Logo"
                          style={{ height: '17px', marginRight: 10 }}
                        />
                        <ListItemContent
                          data-session-type={NMS_TYPES.INTERVENTION}
                          data-index={1}
                          data-session-status={NMS_STATUS.IN_PROGRESS}
                          sx={{ fontWeight: 'bold', fontSize: 13, fontFamily: 'Roboto, sans-serif', marginRight: 5 }}
                        >
                          <span
                            style={{ color: 'black' }}
                            data-session-type={NMS_TYPES.INTERVENTION}
                            data-index={1}
                            data-session-status={NMS_STATUS.IN_PROGRESS}
                          >
                            Interventions
                          </span>
                        </ListItemContent>
                        <Typography level="body-sm" sx={{ fontWeight: 'bold', fontSize: 13 }}>
                          {sessionCounts?.interventionCount}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  ) : null}

                  {pharmacistType === 'follow_up' || pharmacistType === 'both' ? (
                    <ListItem
                      onClick={handleSessionListing}
                      data-session-status={NMS_STATUS.IN_PROGRESS}
                      data-session-type={NMS_TYPES.FOLLOW_UP}
                    >
                      <ListItemButton
                        data-session-type={NMS_TYPES.FOLLOW_UP}
                        data-session-status={NMS_STATUS.IN_PROGRESS}
                        data-index={2}
                        selected={index === 2}
                        style={{ borderRadius: '10px' }}
                        onClick={() => {
                          setSelectedColor('one');
                        }}
                      >
                        <img
                          src={'../../../../content/assets/follow-up-icon.png'}
                          alt="Follow-up Logo"
                          style={{ height: '17px', marginRight: 10 }}
                        />
                        <ListItemContent sx={{ fontWeight: 'bold', fontSize: 13, fontFamily: 'Roboto, sans-serif', marginRight: 5 }}>
                          <span
                            style={{ color: 'black' }}
                            data-session-type={NMS_TYPES.FOLLOW_UP}
                            data-index={2}
                            data-session-status={NMS_STATUS.IN_PROGRESS}
                          >
                            Follow Ups
                          </span>
                        </ListItemContent>
                        <Typography level="body-sm" sx={{ fontWeight: 'bold', fontSize: 13 }}>
                          {sessionCounts?.followUpCount}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  ) : null}
                </List>
              </Popover>
              {pharmacistType === 'intervention' || pharmacistType === 'both' ? (
                <>
                  <ListItem onClick={handleSessionListing} data-session-status={NMS_STATUS.NO_CONSENT} className={'custom_list_item_css'}>
                    <ListItemButton
                      className={'custom_list_button_css'}
                      data-index={5}
                      data-session-status={NMS_STATUS.NO_CONSENT}
                      selected={index === 5}
                      style={{ backgroundColor: selectedColor === 'five' ? '#99D7FF' : 'transparent' }}
                      onClick={() => {
                        setSelectedColor('five');
                      }}
                    >
                      <img
                        data-index={5}
                        data-session-status={NMS_STATUS.NO_CONSENT}
                        src={
                          selectedColor === 'five'
                            ? '../../../../content/assets/no-consent.png'
                            : '../../../../content/assets/no-consent-white.png'
                        }
                        alt="logo"
                        className={'menu_bar_img'}
                      />
                      <ListItemContent
                        data-index={5}
                        data-session-status={NMS_STATUS.NO_CONSENT}
                        className={'custom_list_item_content_css'}
                        sx={{
                          color: selectedColor === 'five' ? '#004CB2' : 'white',
                        }}
                      >
                        No Consent
                      </ListItemContent>
                      <Typography
                        level="body-sm"
                        sx={{ fontWeight: 'bold', color: selectedColor === 'five' ? '#004CB2' : 'white', fontSize: 13 }}
                      >
                        {notConsentCount}
                      </Typography>
                    </ListItemButton>
                  </ListItem>

                  <ListItem onClick={handleSessionListing} data-session-status={NMS_STATUS.NO_REACHABLE} className={'custom_list_item_css'}>
                    <ListItemButton
                      className={'custom_list_button_css'}
                      data-index={6}
                      data-session-status={NMS_STATUS.NO_REACHABLE}
                      selected={index === 6}
                      style={{ backgroundColor: selectedColor === 'six' ? '#99D7FF' : 'transparent' }}
                      onClick={() => {
                        setSelectedColor('six');
                      }}
                    >
                      <img
                        data-index={6}
                        data-session-status={NMS_STATUS.NO_REACHABLE}
                        src={
                          selectedColor === 'six'
                            ? '../../../../content/assets/no-contact.png'
                            : '../../../../content/assets/no-contact-white.png'
                        } // Use the image URL from the array
                        alt="logo"
                        className={'menu_bar_img'}
                      />
                      <ListItemContent
                        data-index={6}
                        data-session-status={NMS_STATUS.NO_REACHABLE}
                        className={'custom_list_item_content_css'}
                        sx={{
                          color: selectedColor === 'six' ? '#004CB2' : 'white',
                        }}
                      >
                        Not Contactable
                      </ListItemContent>
                      <Typography
                        level="body-sm"
                        sx={{ fontWeight: 'bold', color: selectedColor === 'six' ? '#004CB2' : 'white', fontSize: 13 }}
                      >
                        {notReachableCount}
                      </Typography>
                    </ListItemButton>
                  </ListItem>

                  <ListItem onClick={handleSessionListing} data-session-status={NMS_STATUS.COMPLETED} className={'custom_list_item_css'}>
                    <ListItemButton
                      className={'custom_list_button_css'}
                      data-index={7}
                      data-session-status={NMS_STATUS.COMPLETED}
                      selected={index === 7}
                      style={{ backgroundColor: selectedColor === 'seven' ? '#99D7FF' : 'transparent' }}
                      onClick={() => {
                        setSelectedColor('seven');
                      }}
                    >
                      <img
                        data-index={7}
                        data-session-status={NMS_STATUS.COMPLETED}
                        src={
                          selectedColor === 'seven'
                            ? '../../../../content/assets/completed-interventions-pharmacist.png'
                            : '../../../../content/assets/completed-interventions-pharmacist-white.png'
                        } // Use the image URL from the array
                        alt="logo"
                        className={'menu_bar_img'}
                      />
                      <ListItemContent
                        data-index={7}
                        data-session-status={NMS_STATUS.COMPLETED}
                        className={'custom_list_item_content_css'}
                        sx={{
                          color: selectedColor === 'seven' ? '#004CB2' : 'white',
                        }}
                      >
                        Completed Intervention
                      </ListItemContent>
                      <Typography
                        level="body-sm"
                        sx={{ fontWeight: 'bold', color: selectedColor === 'seven' ? '#004CB2' : 'white', fontSize: 13 }}
                      ></Typography>
                    </ListItemButton>
                  </ListItem>

                  <ListItem onClick={handleSessionListing} data-session-status={NMS_STATUS.NOT_STARTED} className={'custom_list_item_css'}>
                    <ListItemButton
                      className={'custom_list_button_css'}
                      data-index={8}
                      data-session-status={NMS_STATUS.NOT_STARTED}
                      selected={index === 8}
                      style={{ backgroundColor: selectedColor === 'eight' ? '#99D7FF' : 'transparent' }}
                      onClick={() => {
                        setSelectedColor('eight');
                      }}
                      sx={{
                        background: isAnimationActive
                          ? 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%)'
                          : 'transparent',
                        backgroundSize: '200% 100%',
                        animation: isAnimationActive ? `${flashAnimation} 0.8s linear infinite` : 'none',
                      }}
                    >
                      <img
                        data-index={8}
                        data-session-status={NMS_STATUS.NOT_STARTED}
                        src={
                          selectedColor === 'eight'
                            ? '../../../../content/assets/call-back.png'
                            : '../../../../content/assets/call-back-white.png'
                        } // Use the image URL from the array
                        alt="logo"
                        className={'menu_bar_img'}
                      />
                      <ListItemContent
                        data-index={8}
                        data-session-status={NMS_STATUS.NOT_STARTED}
                        className={'custom_list_item_content_css'}
                        sx={{
                          color: selectedColor === 'eight' ? '#004CB2' : 'white',
                        }}
                      >
                        Not Started (Call Back)
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                </>
              ) : null}
            </List>

            <div style={{ zIndex: 999999, position: 'relative', textAlign: 'start' }}>
              <Grid
                lg={12}
                container
                sx={{
                  flexDirection: 'row',
                  marginLeft: 0,
                  marginTop: 0,
                  maxHeight: 600,
                }}
              >
                <div
                  style={{
                    borderRadius: '35px', // Circular corners
                    padding: 20, // Padding for internal spacing
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Optional shadow for a 3D effect
                    maxWidth: '100%', // Ensures box width fits well within the container
                    margin: 'auto', // Center the box horizontally
                    border: '1px solid white', // White border
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: 38,
                      borderRadius: 1,
                      alignSelf: 'left',
                      justifyContent: 'left',
                      alignItems: 'left',
                    }}
                  >
                    <h1
                      style={{
                        color: '#FFFFFF',
                        fontSize: 18,
                        fontWeight: 1000,
                        marginTop: 8,
                        paddingLeft: '5px',
                        fontFamily: 'Roboto, sans-serif',
                        textDecoration: 'underline',
                      }}
                    >
                      Pharmacy Details
                    </h1>
                  </div>

                  <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: 'inherit' }}>
                    <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center' }}>
                      <div style={{ height: 35, width: 50, backgroundColor: '' }}>
                        <h1
                          style={{
                            color: 'white',
                            fontSize: 13,
                            marginTop: 5,
                            marginLeft: 5,
                            fontWeight: 400,
                            alignSelf: 'center',
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          <LocalPharmacyIcon />
                        </h1>
                      </div>
                      <div style={{ width: 150, backgroundColor: '' }}>
                        <h1
                          style={{
                            fontSize: 13,
                            color: 'white',
                            marginTop: 8,
                            marginLeft: 0,
                            fontWeight: 400,
                            alignSelf: 'center',
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {pharmacyDetail?.name}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: 'inherit' }}>
                    <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center' }}>
                      <div style={{ height: 35, width: 50, backgroundColor: '' }}>
                        <h1
                          style={{
                            color: 'white',
                            fontSize: 13,
                            marginTop: 5,
                            marginLeft: 5,
                            fontWeight: 400,
                            alignSelf: 'center',
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          <AccountBoxIcon />
                        </h1>
                      </div>
                      <div style={{ width: 150, backgroundColor: '' }}>
                        <h1
                          style={{
                            fontSize: 13,
                            color: 'white',
                            marginTop: 8,
                            marginLeft: 0,
                            fontWeight: 400,
                            alignSelf: 'center',
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {pharmacyDetail?.manager_name}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: 'inherit' }}>
                    <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center' }}>
                      <div style={{ height: 35, width: 50, backgroundColor: '' }}>
                        <h1
                          style={{
                            color: 'white',
                            fontSize: 13,
                            marginTop: 5,
                            marginLeft: 5,
                            fontWeight: 400,
                            alignSelf: 'center',
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          <LocalPhoneIcon />
                        </h1>
                      </div>
                      <div style={{ width: 150, backgroundColor: '' }}>
                        <h1
                          style={{
                            fontSize: 13,
                            color: 'white',
                            marginTop: 5,
                            marginLeft: 0,
                            fontWeight: 400,
                            alignSelf: 'center',
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {pharmacyDetail?.contact_number}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: 'inherit' }}>
                    <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center' }}>
                      <div style={{ height: 35, width: 50, backgroundColor: '' }}>
                        <h1
                          style={{
                            color: 'white',
                            fontSize: 13,
                            marginTop: 5,
                            marginLeft: 5,
                            fontWeight: 400,
                            alignSelf: 'center',
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          <AccessTimeIcon />
                        </h1>
                      </div>
                      <div style={{ width: 150, backgroundColor: '' }}>
                        <h1
                          style={{
                            fontSize: 13,
                            color: 'white',
                            marginTop: 5,
                            marginLeft: 0,
                            fontWeight: 400,
                            alignSelf: 'center',
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {pharmacyDetail?.startTime} to {pharmacyDetail?.endTime}
                        </h1>
                      </div>
                    </div>
                    <Divider style={{ backgroundColor: 'white', marginTop: '20px', alignSelf: 'center', height: '2px' }} />
                  </div>
                  <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: 'inherit' }}>
                    <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center' }}>
                      <div style={{ height: 35, width: 50, backgroundColor: '' }}>
                        <h1
                          style={{
                            color: 'white',
                            fontSize: 13,
                            marginTop: 5,
                            marginLeft: 5,
                            fontWeight: 400,
                            alignSelf: 'center',
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          <PinDropIcon />{' '}
                        </h1>
                      </div>
                      <div style={{ width: 150, backgroundColor: '' }}>
                        <h1
                          style={{
                            color: 'white',
                            fontSize: 13,
                            marginTop: 5,
                            marginLeft: 0,
                            fontWeight: 400,
                            alignSelf: 'center',
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {pharmacyDetail?.address}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </div>
          </Grid>
        ) : (
          <Grid
            lg={10}
            xs={10}
            sm={10}
            style={{
              textAlign: 'center', // Center-align content
              zIndex: 99999,
            }}
          >
            <Grid
              item
              style={{
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center', // Vertically align items
                justifyContent: 'center', // Adjust horizontal alignment
              }}
            >
              <img
                src={'../../../../content/logos/final-logo.png'}
                alt="Logo"
                style={{
                  width: 'auto',
                  height: '45px',
                }}
              />
            </Grid>
            <hr style={{ marginTop: 0, color: 'white' }} />
            <List
              aria-label="Sidebar"
              className={'custom_list_css'}
              sx={{
                '--ListItem-paddingLeft': '0px',
                '--ListItemDecorator-size': '20px',
                '--ListItem-minHeight': '10px',
                '--List-nestedInsetStart': '10px',
              }}
            >
              <ListItem className={'custom_list_item_css'}>
                <ListItemButton
                  onClick={() => (showOptions === false ? setShowOptions(true) : setShowOptions(false))}
                  className={'custom_list_button_css'}
                  style={{
                    backgroundColor: selectedColor === ('one' || 'two') ? 'rgb(153, 215, 255)' : 'transparent',
                    cursor: 'pointer',
                  }}
                >
                  <img
                    src={
                      selectedColor === ('one' || 'two')
                        ? '../../../../content/assets/in-progress.png'
                        : '../../../../content/assets/in-progress-white.png'
                    } // Use the image URL from the array
                    alt="logo"
                    className={'menu_bar_img'}
                  />
                </ListItemButton>
              </ListItem>

              <Popover
                open={showOptions}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 100, left: 80 }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <List>
                  {pharmacistType === 'intervention' || pharmacistType === 'both' ? (
                    <ListItem
                      onClick={handleSessionListing}
                      data-session-status={NMS_STATUS.IN_PROGRESS}
                      data-session-type={NMS_TYPES.INTERVENTION}
                    >
                      <ListItemButton
                        data-session-type={NMS_TYPES.INTERVENTION}
                        data-session-status={NMS_STATUS.IN_PROGRESS}
                        selected={index === 1}
                        style={{ borderRadius: '10px' }}
                        onClick={() => {
                          setSelectedColor('one');
                        }}
                      >
                        <img
                          src={'../../../../content/assets/intervention-logo.png'}
                          alt="Intervention Logo"
                          style={{ height: '17px', marginRight: 10 }}
                        />
                        <ListItemContent
                          data-session-type={NMS_TYPES.INTERVENTION}
                          data-index={1}
                          data-session-status={NMS_STATUS.IN_PROGRESS}
                          sx={{ fontWeight: 'bold', fontSize: 13, fontFamily: 'Roboto, sans-serif', marginRight: 5 }}
                        >
                          <span
                            style={{ color: 'black' }}
                            data-session-type={NMS_TYPES.INTERVENTION}
                            data-index={1}
                            data-session-status={NMS_STATUS.IN_PROGRESS}
                          >
                            Interventions
                          </span>
                        </ListItemContent>
                        <Typography level="body-sm" sx={{ fontWeight: 'bold', fontSize: 13 }}>
                          {sessionCounts?.interventionCount}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  ) : null}
                  {pharmacistType === 'follow_up' || pharmacistType === 'both' ? (
                    <ListItem
                      onClick={handleSessionListing}
                      data-session-status={NMS_STATUS.IN_PROGRESS}
                      data-session-type={NMS_TYPES.FOLLOW_UP}
                    >
                      <ListItemButton
                        data-session-type={NMS_TYPES.FOLLOW_UP}
                        data-session-status={NMS_STATUS.IN_PROGRESS}
                        data-index={2}
                        selected={index === 2}
                        style={{ borderRadius: '10px' }}
                        onClick={() => {
                          setSelectedColor('one');
                        }}
                      >
                        <img
                          src={'../../../../content/assets/follow-up-icon.png'}
                          alt="Follow-up Logo"
                          style={{ height: '17px', marginRight: 10 }}
                        />
                        <ListItemContent sx={{ fontWeight: 'bold', fontSize: 13, fontFamily: 'Roboto, sans-serif', marginRight: 5 }}>
                          <span
                            style={{ color: 'black' }}
                            data-session-type={NMS_TYPES.FOLLOW_UP}
                            data-index={2}
                            data-session-status={NMS_STATUS.IN_PROGRESS}
                          >
                            Follow Ups
                          </span>
                        </ListItemContent>
                        <Typography level="body-sm" sx={{ fontWeight: 'bold', fontSize: 13 }}>
                          {sessionCounts?.followUpCount}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  ) : null}
                </List>
              </Popover>
              {pharmacistType === 'intervention' || pharmacistType === 'both' ? (
                <>
                  <Tooltip title={notConsentCount}>
                    <ListItem onClick={handleSessionListing} data-session-status={NMS_STATUS.NO_CONSENT} className={'custom_list_item_css'}>
                      <ListItemButton
                        data-index={5}
                        data-session-status={NMS_STATUS.NO_CONSENT}
                        selected={index === 5}
                        className={'custom_list_button_css'}
                        style={{ backgroundColor: selectedColor === 'five' ? 'rgb(153, 215, 255)' : 'transparent' }}
                        onClick={() => {
                          setSelectedColor('five');
                        }}
                      >
                        <img
                          data-index={5}
                          data-session-status={NMS_STATUS.NO_CONSENT}
                          src={
                            selectedColor === 'five'
                              ? '../../../../content/assets/no-consent.png'
                              : '../../../../content/assets/no-consent-white.png'
                          } // Use the image URL from the array
                          alt="logo"
                          className={'menu_bar_img'}
                        />
                        {/*<Typography level="body-sm" sx={{ fontWeight: 'bold', color: 'white', fontSize: 13 }}>*/}
                        {/*  {notConsentCount}*/}
                        {/*</Typography>*/}
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                  <Tooltip title={notReachableCount}>
                    <ListItem
                      onClick={handleSessionListing}
                      data-session-status={NMS_STATUS.NO_REACHABLE}
                      className={'custom_list_item_css'}
                    >
                      <ListItemButton
                        className={'custom_list_button_css'}
                        data-index={6}
                        data-session-status={NMS_STATUS.NO_REACHABLE}
                        selected={index === 6}
                        style={{ backgroundColor: selectedColor === 'six' ? 'rgb(153, 215, 255)' : 'transparent' }}
                        onClick={() => {
                          setSelectedColor('six');
                        }}
                      >
                        <img
                          data-index={6}
                          data-session-status={NMS_STATUS.NO_REACHABLE}
                          src={
                            selectedColor === 'six'
                              ? '../../../../content/assets/no-contact.png'
                              : '../../../../content/assets/no-contact-white.png'
                          } // Use the image URL from the array
                          alt="logo"
                          className={'menu_bar_img'}
                        />
                        {/*<Typography level="body-sm" sx={{ fontWeight: 'bold', color: 'white', fontSize: 13 }}>*/}
                        {/*  {notReachableCount}*/}
                        {/*</Typography>*/}
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                  <ListItem onClick={handleSessionListing} data-session-status={NMS_STATUS.COMPLETED} className={'custom_list_item_css'}>
                    <ListItemButton
                      className={'custom_list_button_css'}
                      data-index={7}
                      data-session-status={NMS_STATUS.COMPLETED}
                      selected={index === 7}
                      style={{ backgroundColor: selectedColor === 'seven' ? 'rgb(153, 215, 255)' : 'transparent' }}
                      onClick={() => {
                        setSelectedColor('seven');
                      }}
                    >
                      <img
                        data-index={7}
                        data-session-status={NMS_STATUS.COMPLETED}
                        src={
                          selectedColor === 'seven'
                            ? '../../../../content/assets/completed-interventions-pharmacist.png'
                            : '../../../../content/assets/completed-interventions-pharmacist-white.png'
                        } // Use the image URL from the array
                        alt="logo"
                        className={'menu_bar_img'}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem onClick={handleSessionListing} data-session-status={NMS_STATUS.NOT_STARTED} className={'custom_list_item_css'}>
                    <ListItemButton
                      className={'custom_list_button_css'}
                      data-index={8}
                      data-session-status={NMS_STATUS.NOT_STARTED}
                      selected={index === 8}
                      style={{ backgroundColor: selectedColor === 'eight' ? 'rgb(153, 215, 255)' : 'transparent' }}
                      onClick={() => {
                        setSelectedColor('eight');
                      }}
                    >
                      <img
                        data-index={8}
                        data-session-status={NMS_STATUS.NOT_STARTED}
                        src={
                          selectedColor === 'eight'
                            ? '../../../../content/assets/call-back.png'
                            : '../../../../content/assets/call-back-white.png'
                        } // Use the image URL from the array
                        alt="logo"
                        className={'menu_bar_img'}
                      />
                    </ListItemButton>
                  </ListItem>
                </>
              ) : null}
            </List>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
