export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  PHARMACIST: 'PHARMACIST',
  CLIENT: 'CLIENT',
  ADMINISTRATOR: 'ADMINISTRATOR',
  DEO: 'DEO',
  LEAD_DEO: 'LEAD_DEO',
  MANAGER_DEO: 'MANAGER_DEO',
  MANAGE_PHARMACIST: 'MANAGE_PHARMACIST',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const NMS_TYPES = {
  INTERVENTION: 1,
  FOLLOW_UP: 2,
};
export const NMS_STATUS = {
  IN_PROGRESS: 1,
  OVERDUE: 2,
  COMPLETED: 3,
  NOT_STARTED: 10,
  NO_CONSENT: 4,
  NO_REACHABLE: 5,
  IN_ELIGIBLE: 6,
  INCORRECT_NUMBER: 7,
  INTERVENTION_NOT_DELIVERED: 8,
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
