import { useState } from 'react';
import { useAppDispatch } from 'app/config/store';
import { callBack } from 'app/entities/session/session.reducer';
import { useNavigate } from 'react-router-dom';

const pharmacistDetailPageHook = () => {
  const [openCallback, setOpenCallback] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [callBackValues, setCallBackValues] = useState({
    date: '',
    time: '',
    comment: '',
  });

  const [errorsCallBack, setErrorsCallBack] = useState({ date: '', time: '', comment: '' });

  const handleClickOpenCallBack = () => {
    setOpenCallback(true);
  };

  const handleCloseCallBack = () => {
    setOpenCallback(false);
    setCallBackValues({ date: '', time: '', comment: '' });
    setErrorsCallBack({ date: '', time: '', comment: '' });
  };

  const setValuesCallBack = (field, value) => {
    setCallBackValues(prev => ({ ...prev, [field]: value }));
  };

  const validate = () => {
    const newErrors = { date: '', time: '', comment: '' };
    let isValid = true;

    if (!callBackValues.date) {
      newErrors.date = 'Date is required';
      isValid = false;
    }
    if (!callBackValues.time) {
      newErrors.time = 'Time is required';
      isValid = false;
    }
    if (!callBackValues.comment.trim()) {
      newErrors.comment = 'Comment is required';
      isValid = false;
    }

    setErrorsCallBack(newErrors);
    return isValid;
  };

  const handleSubmitCallBack = async (id: number) => {
    if (validate() && id) {
      const params = {
        ...callBackValues,
        id,
      };
      const res = await dispatch(callBack(params));
      console.log('Submitted Values:', res);
      if (res.type === 'session/add-call-back/:id/fulfilled' && res.payload['status'] === 201) {
        setOpenCallback(false);
        navigate('/');
      }
    }
  };

  const states = {
    openCallback,
    errorsCallBack,
    callBackValues,
  };

  const handlers = {
    handleClickOpenCallBack,
    handleCloseCallBack,
    handleSubmitCallBack,
    setValuesCallBack,
  };
  return {
    states,
    handlers,
  };
};
export default pharmacistDetailPageHook;
