import './header.scss';

import * as React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  Button,
  MenuItem,
  Divider,
  FormControl,
  Select,
  FormHelperText,
  Popover,
  Box,
  Typography,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import { LoadingBar } from 'react-redux-loading-bar';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMultiplePharmacy, logout, setPharmacy } from 'app/shared/reducers/authentication';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { isSet } from 'lodash';
import { isNumber } from 'react-jhipster';
import { getAllNotStartedSessionsByPharmacy, getAllSessionsByPharmacy } from 'app/entities/session/session.reducer';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import { SwitchAccessShortcut, SwitchAccessShortcutAddOutlined } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDashboardContext } from 'app/shared/layout/header/LeftBarContext';

const role = localStorage.getItem('role') === null ? '' : localStorage.getItem('role');
const pages = ['Dashboard', 'New Medicine Service ', 'User Guide', 'Contact Us'];
const pageImages = [
  '../../../../content/assets/dashboard-svgrepo-com.png',
  '../../../../content/assets/services-svgrepo-com.png',
  '../../../../content/assets/guide-svgrepo-com.png',
  '../../../../content/assets/email-1-svgrepo-com.png',
];
interface ISessionListParams {
  sort: any;
  service_id: any;
  session_status: any;
  session_type: any;
  pageNo: any;
  searchParams: any;
  rows: any;
  q: any;
}
export interface IHeaderProps {
  isAuthenticated: boolean;
  isLocumDetailsVerify: boolean;
  isDEO: boolean;
  isLeadDEO: boolean;
  isManagerDEO: boolean;
  isManagePharmacist: boolean;
  isAdmin: boolean;
  isClient: boolean;
  isPharmacist: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
  serviceEntities: Array<any>;
}

const Header = (props: IHeaderProps) => {
  const pharmacyName = JSON.parse(localStorage.getItem('pharmacy_name'));
  const { isAuthenticated, isLocumDetailsVerify, isPharmacist, isDEO, isAdmin, isClient, isLeadDEO, isManagerDEO, isManagePharmacist } =
    props;
  const location = useLocation();
  const [locumVerify, setLocumVerify] = useState(false);
  const [setAnchorElNav] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const { serviceEntities } = props;
  const dispatch = useAppDispatch();
  const { pharmacy_list, account } = useAppSelector(state => state.authentication);
  const [pharmacyValue, setPharmacyValue] = useState(pharmacyName);
  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog open/close
  const [selectedPage, setSelectedPage] = useState(1);
  console.log({ location });
  useEffect(() => {
    const id = account?.id;
    const params = { id, search: '' };
    if (isPharmacist) {
      dispatch(getMultiplePharmacy(params));
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem('locumVerified')) {
      setLocumVerify(true);
    }
  }, []);

  const handleProfileIconClick = () => {
    setDialogOpen(true); // Open the dialog when profile icon is clicked
  };

  const handleCloseDialog = () => {
    setDialogOpen(false); // Close the dialog
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectPage = pageId => {
    setSelectedPage(pageId);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const handleDashboardRedirect = () => {
    const lastActiveTabAdmin = localStorage.getItem('lastActiveTabAdmin');
    const tabStateAdmin = lastActiveTabAdmin ? JSON.parse(lastActiveTabAdmin) : { selectedColor: 'one', index: 1 };
    const lastActiveTabDeo = localStorage.getItem('lastActiveTabDeo');
    const tabStateDeo = lastActiveTabDeo ? JSON.parse(lastActiveTabDeo) : { activeItem: 'Daily-Summary' };
    if (isPharmacist) {
      navigate('/');
    } else if (isDEO || isLeadDEO || isManagerDEO) {
      if (localStorage.getItem('fileId')) {
        navigate('/operator/session-listing');
      } else {
        navigate('operator/dashboard', { state: JSON.stringify(tabStateDeo) });
        localStorage.setItem('lastActiveTabDeo', JSON.stringify(tabStateDeo));
      }
    } else if (isAdmin) {
      navigate('/admin/dashboard', { state: tabStateAdmin });
      localStorage.setItem('lastActiveTabAdmin', JSON.stringify({ selectedColor: 'one', index: 1 }));
    } else if (isClient) {
      navigate('client/dashboard');
    }
  };
  const handleSelectedPharmacy = event => {
    const pharmacy = event.target.value;
    setPharmacyValue(pharmacy);
    dispatch(setPharmacy(pharmacy)).then(r => {
      const data = {
        service_id: 1,
        session_status: '1',
        session_type: localStorage.getItem('pharmacist_type') === '"follow_up"' ? '2' : '1',
        pageNo: 0,
        rows: 50,
        q: '',
      };
      dispatch(getAllSessionsByPharmacy(data as ISessionListParams));
      navigate('/');
    });
    dispatch(getAllNotStartedSessionsByPharmacy());
  };
  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */
  const { showMenu, handleDashboardSwitch } = useDashboardContext();

  return (
    <AppBar position="static" sx={{ backgroundColor: '#FFFFFF', height: 55 }}>
      <LoadingBar className="loading-bar" />

      {isAuthenticated && (
        <Toolbar>
          {![
            '/admin/dashboard',
            '/operator/session-listing',
            '/operator/dashboard',
            '/client/dashboard',
            '/',
            '/manage-pharmacist/dashboard',
          ].includes(location?.pathname) && (
            <img
              onClick={handleDashboardRedirect}
              src={'../../../../content/assets/pharmacy-solution-logo.png'}
              alt="Logo"
              style={{
                width: 'auto',
                height: '45px',
                marginLeft: 10,
                marginBottom: 10,
                cursor: 'pointer', // Add this line to change cursor on hover
                zIndex: 3,
              }}
            />
          )}

          {isPharmacist && (locumVerify || isLocumDetailsVerify) && (
            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                alignSelf: 'center',
                justifyContent: 'space-between',
                marginLeft: showMenu ? '4%' : '15%',
              }}
            >
              {['/'].includes(location?.pathname) && (
                <>
                  {showMenu === false ? (
                    <Tooltip title={'Collapse'}>
                      <img
                        onClick={handleDashboardSwitch}
                        src={'../../../content/assets/close-dashboard.png'}
                        alt="Logo"
                        style={{
                          width: '24px',
                          height: '24px',
                          marginBottom: 10,
                          cursor: 'pointer',
                          transition: 'ease 0.5s',
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={'Expand'}>
                      <img
                        onClick={handleDashboardSwitch}
                        src={'../../../content/assets/close-dashboard.png'}
                        alt="Logo"
                        style={{
                          width: '24px',
                          height: '24px',
                          marginBottom: 10,
                          cursor: 'pointer',
                          transform: 'scaleX(-1)',
                          transition: '0.5s ease',
                        }}
                      />
                    </Tooltip>
                  )}
                </>
              )}

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                style={{ marginTop: '32px' }} // Adjust the positioning as needed
              >
                {serviceEntities &&
                  serviceEntities.length > 0 &&
                  serviceEntities.map((item, index) => (
                    <MenuItem key={index} style={{ width: 100 }}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Menu>
            </div>
          )}

          {isClient && (
            <>
              <div
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  alignSelf: 'center',
                  justifyContent: 'space-between',
                  marginLeft: showMenu ? '4%' : '15%',
                }}
              >
                {['/client/dashboard'].includes(location?.pathname) && (
                  <>
                    {showMenu === false ? (
                      <Tooltip title={'Collapse'}>
                        <img
                          onClick={handleDashboardSwitch}
                          src={'../../../content/assets/close-dashboard.png'}
                          alt="Logo"
                          style={{
                            width: '24px',
                            height: '24px',
                            marginBottom: 10,
                            cursor: 'pointer',
                            transition: 'ease 0.5s',
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={'Expand'}>
                        <img
                          onClick={handleDashboardSwitch}
                          src={'../../../content/assets/close-dashboard.png'}
                          alt="Logo"
                          style={{
                            width: '24px',
                            height: '24px',
                            marginBottom: 10,
                            cursor: 'pointer',
                            transform: 'scaleX(-1)',
                            transition: '0.5s ease',
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          {isManagePharmacist && (
            <>
              <div
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  alignSelf: 'center',
                  justifyContent: 'space-between',
                  marginLeft: showMenu ? '4%' : '15%',
                }}
              >
                {['/manage-pharmacist/dashboard'].includes(location?.pathname) && (
                  <>
                    {showMenu === false ? (
                      <Tooltip title={'Collapse'}>
                        <img
                          onClick={handleDashboardSwitch}
                          src={'../../../content/assets/close-dashboard.png'}
                          alt="Logo"
                          style={{
                            width: '24px',
                            height: '24px',
                            marginBottom: 10,
                            cursor: 'pointer',
                            transition: 'ease 0.5s',
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={'Expand'}>
                        <img
                          onClick={handleDashboardSwitch}
                          src={'../../../content/assets/close-dashboard.png'}
                          alt="Logo"
                          style={{
                            width: '24px',
                            height: '24px',
                            marginBottom: 10,
                            cursor: 'pointer',
                            transform: 'scaleX(-1)',
                            transition: '0.5s ease',
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          {(isDEO || isLeadDEO || isManagerDEO) && (
            <>
              <div
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  alignSelf: 'center',
                  justifyContent: 'space-between',
                  marginLeft: showMenu ? '4%' : '15%',
                }}
              >
                {['/operator/dashboard'].includes(location?.pathname) && (
                  <>
                    {showMenu === false ? (
                      <Tooltip title={'Collapse'}>
                        <img
                          onClick={handleDashboardSwitch}
                          src={'../../../content/assets/close-dashboard.png'}
                          alt="Logo"
                          style={{
                            width: '24px',
                            height: '24px',
                            marginBottom: 10,
                            cursor: 'pointer',
                            transition: 'ease 0.5s',
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={'Expand'}>
                        <img
                          onClick={handleDashboardSwitch}
                          src={'../../../content/assets/close-dashboard.png'}
                          alt="Logo"
                          style={{
                            width: '24px',
                            height: '24px',
                            marginBottom: 10,
                            cursor: 'pointer',
                            transform: 'scaleX(-1)',
                            transition: '0.5s ease',
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          {isAdmin && (
            <>
              <div
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  alignSelf: 'center',
                  justifyContent: 'space-between',
                  marginLeft: showMenu ? '4%' : '15%',
                }}
              >
                {['/admin/dashboard'].includes(location?.pathname) && (
                  <>
                    {showMenu === false ? (
                      <Tooltip title={'Collapse'}>
                        <img
                          onClick={handleDashboardSwitch}
                          src={'../../../content/assets/close-dashboard.png'}
                          alt="Logo"
                          style={{
                            width: '24px',
                            height: '24px',
                            marginBottom: 10,
                            cursor: 'pointer',
                            transition: 'ease 0.5s',
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={'Expand'}>
                        <img
                          onClick={handleDashboardSwitch}
                          src={'../../../content/assets/close-dashboard.png'}
                          alt="Logo"
                          style={{
                            width: '24px',
                            height: '24px',
                            marginBottom: 10,
                            cursor: 'pointer',
                            transform: 'scaleX(-1)',
                            transition: '0.5s ease',
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              alignSelf: 'center',
              justifyContent: 'flex-end',
              marginRight: -8,
              marginBottom: 10,
            }}
          >
            {isPharmacist && (locumVerify || isLocumDetailsVerify) && (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                {/*{isPharmacist && (locumVerify || isLocumDetailsVerify) && (*/}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  <SwitchAccessShortcutAddOutlined style={{ height: '19px', color: '#99D7FF', marginRight: 5 }} />
                  <span style={{ marginRight: '10px', color: 'black', fontSize: '11px', fontWeight: 500 }}>SWITCH PHARMACY:</span>
                  <FormControl>
                    <Select
                      className="outline"
                      id="demo-simple-select"
                      name="pharmacy"
                      value={pharmacyValue || ''}
                      displayEmpty
                      onChange={handleSelectedPharmacy}
                      placeholder={'Switch Pharmacy'}
                      style={{
                        height: '40px',
                        fontSize: '12px',
                        maxWidth: '300px',
                        backgroundColor: '#F3F3F3',
                        color: '#4E4E4E',
                        borderColor: '#DBDBDB',
                        fontWeight: 'bold',
                      }}
                      renderValue={selected => <span style={{ marginLeft: 5 }}>{selected?.name || pharmacyName}</span>}
                    >
                      <MenuItem disabled value="">
                        Select Pharmacy
                      </MenuItem>
                      {pharmacy_list?.map((elem, index) => (
                        <MenuItem key={index} value={elem}>
                          {elem?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {/*)}*/}
              </div>
            )}
          </div>
          <div style={{ marginBottom: 7 }}>
            <img
              src={'../../../../content/assets/profile1.png'}
              alt="Profile"
              style={{ height: '35px', cursor: 'pointer', marginLeft: '20px' }}
              onClick={handleProfileIconClick} // Open the Popover when clicked
            />

            {/* Popover for Profile Details */}

            <Popover
              open={dialogOpen}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 20, left: 2000 }} // Center the popover horizontally
              onClose={handleCloseDialog}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                sx: {
                  width: { xs: '30%', sm: '22%', md: '10%' }, // Responsive width for different breakpoints
                  padding: 2, // Padding around the content
                  borderRadius: 2,
                },
              }}
            >
              <Box>
                <Typography variant="h6" sx={{ textAlign: 'left', color: '#236EBA', fontSize: '12px' }}>
                  {account?.firstName}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'left', color: '#7E7E7E', fontSize: 10 }}>
                  Logged in as {role}
                </Typography>
              </Box>
              <Divider style={{ marginTop: 5 }} />
              <Button
                fullWidth
                onClick={handleLogout}
                sx={{
                  justifyContent: 'flex-start',
                  color: 'red',
                  margin: '10px 0',
                  padding: 1,
                  paddingLeft: 2,
                }}
              >
                Logout
                <Box
                  component="img"
                  src={'../../../../content/assets/logout-logo.png'}
                  alt="logo"
                  sx={{ marginLeft: 'auto' }} // Adjust size and alignment for responsiveness
                />
              </Button>
            </Popover>
          </div>
        </Toolbar>
      )}
    </AppBar>
  );
};

export default Header;
